import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import DynamicComponent from '../utils/dynamic-component';
import Layout from '../components/layout/layout';

function IndexPage({ data, location }) {
  return (
    <Layout
      legalFootNote={data.contentfulPageUtility?.legalFootNote?.legalFootNote}
      location={location}
    >
      {data.contentfulPageUtility.components.filter(component => component?.__typename)
        .map(component => (
          <section key={component.id}>
            {DynamicComponent(component.__typename, component)}
          </section>
        ))}
    </Layout>
  );
}

export const query = graphql`
  query Utility {
    contentfulPageUtility(type: {eq: "Home Page"}) {
      legalFootNote {
        legalFootNote
      }
      components {
        ...componentContainer
      }
    }
  }

  fragment componentContainer on ContentfulCompContainer {
    id
    __typename
    backgroundImage {
      file {
        url
      }
    }
    backgroundColor
    rows {
      title
      entryTitle
      alignItems
      justifyContent
      alignItems
      spacing
      anchorLinkId
      leftPadding
      topPadding
      rightPadding
      bottomPadding
      columns {
        __typename
        textColor
        columnSize
        isCard
        component {
          __typename
          ...imageHome
          ...carouselHome
          ...accordianHome
          ...richTextWithButtonHome
          ...stepperHome
          ...tableHome
          ...cardHome
        }
      }
    }
  }

  fragment accordianHome on ContentfulCompAccordianContainer {
    id
    accordianTitle
    accordianItems {
      accordianBody {
        raw
      }
      accordianHeader
      __typename
    }
  }

  fragment imageHome on ContentfulCompImageOnly {
    id
    imageTitle
    image {
      file {
        url
        fileName
      }
      gatsbyImageData(
        jpegProgressive: true
        formats: WEBP
        placeholder: BLURRED
      )
    }
  }

  fragment carouselHome on ContentfulRanCompCarousel {
    slides {
      button {
        ...buttonLinks
      }
      slideLink {
        ...buttonLinks
      }
      date(formatString: "MMMM DD, YYYY")
      entryTitle
      description {
        description
      }
      image {
        gatsbyImageData(
          aspectRatio: 1.78
          resizingBehavior: SCALE
          jpegProgressive: true
          formats: WEBP
          placeholder: BLURRED
        )
      }
      title
      category
    }
    title
  }

  fragment richTextWithButtonHome on ContentfulCompRichTextWithButton {
    heading
    content {
      raw
    }
    button {
      link {
        ... on ContentfulInternalLink {
          openInANewTab
          entryTitle
          refPage {
            __typename
            ... on ContentfulPageProgram {
              slug
            }
            ... on ContentfulPageUtility {
              slug
            }
            ... on ContentfulPageEvent {
              slug
            }
            ... on ContentfulPageKnowledge {
              slug
            }
          }
        }
        ... on ContentfulCompExternalLink {
          title
          url {
            url
          }
          openInANewTab
          logo {
            file {
              fileName
              url
              contentType
            }
          }
        }
      }
    }
  }

  fragment stepperHome on ContentfulCompStepperContainer {
    id
    stepperTitle
    stepperNumber
    stepperItems {
      stepperHeader
      stepperBody {
        raw
      }
      stepperButton {
        title
        link {
          ... on ContentfulInternalLink {
            openInANewTab
            entryTitle
            refPage {
              __typename
              ... on ContentfulPageProgram {
                slug
              }
              ... on ContentfulPageUtility {
                slug
              }
              ... on ContentfulPageEvent {
                slug
              }
              ... on ContentfulPageKnowledge {
                slug
              }
            }
          }
          ...on ContentfulCompExternalLink {
            title
            url {
              url
            }
            openInANewTab
            logo {
              file {
                fileName
                url
                contentType
              }
            }
          }
        }
      }
      stepperAssets {
        id
        title
        downloadLink {
          file {
            url
            fileName
          }
        }
        openInANewTab
      }
      stepperImage {
        gatsbyImageData(
          aspectRatio: 1.78
          jpegProgressive: true
          formats: WEBP
          resizingBehavior: SCALE
          placeholder: BLURRED
          cornerRadius: 10
        )
      }
    }
  }

  fragment tableHome on ContentfulCompTable {
    id
    tableHeader
    entryTitle
    tableRows {
      id
      entryTitle
      cellValues
    }
  }

  fragment cardHome on ContentfulCompRichTextBlock {
    id
    __typename
    entryTitle
    richText {
      raw
    }
  }
`;
IndexPage.propTypes = {
  location: PropTypes.shape.isRequired,
  data: PropTypes.shape.isRequired
};

export default IndexPage;
